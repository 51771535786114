import { CentralKitchen } from 'models/entities/central-kitchen';

class Condition {

  readonly deleted: boolean;

  constructor() {
    this.deleted = false;
  }

  filter(all: CentralKitchen[]): CentralKitchen[] {
    return all.filter(it => this.check(it));
  }

  private check(centralKitchen: CentralKitchen): boolean {
    return true;
  }

}

export { Condition };