import { CentralKitchen, CentralKitchenCollection } from 'models/entities/central-kitchen';
import { CompanyCollection } from 'models/entities/company';
import { BrandCollection } from 'models/entities/brand';
import { Store } from 'models/entities/store';
import { SatelliteKitchen } from 'models/entities/satellite-kitchen';
import { User, UserEditor } from 'models/entities/user';

import { Condition } from './models/condition';
import { ReadGql } from './models/read-gql';

class Model {

  readonly condition: Condition;
  readonly collection?: CentralKitchenCollection;
  readonly list?: CentralKitchen[];
  readonly companies?: CompanyCollection;
  readonly brands?: BrandCollection;
  readonly stores?: Store[];

  constructor() {
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const gql = await new ReadGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    const collection = new CentralKitchenCollection(gql.result.collection);
    const list = this.condition.filter(collection.documents);
    const companies = new CompanyCollection(gql.result.companies);
    const brands = new BrandCollection(gql.result.brands);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list, companies, brands });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCompanies(): Promise<this> {
    if (!this.companies) throw new Error('companies is undefined');
    const companies = await this.companies.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, companies });
  }

  async readAllBrands(): Promise<this> {
    if (!this.brands) throw new Error('brands is undefined');
    const brands = await this.brands.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, brands });
  }

  initStores(): this {
    if (!this.brands) throw new Error('brands is undefined');
    const stores = this.brands.getStores();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, stores });
  }

  build(): CentralKitchen {
    return new CentralKitchen();
  }

  add(brand: CentralKitchen): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(brand));
  }

  replace(brand: CentralKitchen): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(brand));
  }

  remove(brand: CentralKitchen): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(brand));
  }

  private apply(collection: CentralKitchenCollection): this {
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  getStoresExcept(satelliteKitchens: SatelliteKitchen[]): Store[] {
    if (!this.stores) throw new Error('stores is undefined');
    const ids = satelliteKitchens.filter(it => it.store).map(it => it.store!.id);
    return this.stores.filter(it => !ids.includes(it.id));
  }

  buildSatelliteKitchen(centralKitchen: CentralKitchen): SatelliteKitchen {
    return new SatelliteKitchen({ centralKitchenId: centralKitchen.id });
  }

  applySatelliteKitchen(satelliteKitchen: SatelliteKitchen): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.applySatelliteKitchen(satelliteKitchen));
  }

  removeSatelliteKitchen(satelliteKitchen: SatelliteKitchen): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.removeSatelliteKitchen(satelliteKitchen));
  }

  buildUser(centralKitchen: CentralKitchen): User {
    return new User({ organizationId: centralKitchen.id });
  }

  buildUserEditor(original: User): UserEditor {
    return new UserEditor({ original });
  }

  incrementUserCount(centralKitchen: CentralKitchen): this {
    return this.replace(centralKitchen.incrementUserCount());
  }

}

export { Model };