import styled from 'styled-components';

import { baseFontSize } from 'views/services/font-scheme';
import { textColor, primaryColor, primaryTextColor } from 'views/services/color-scheme';

export const Root = styled.div`
  width: 32px;
  height: 32px;
  & > button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-size: calc(${baseFontSize} * 1);
    color: ${textColor};
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${primaryColor};
      color: ${primaryTextColor};
    }
  }
`;