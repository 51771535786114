import React, { useState, useEffect } from 'react';

import { CentralKitchen } from 'models/entities/central-kitchen';
import { SatelliteKitchen } from 'models/entities/satellite-kitchen';
import { User } from 'models/entities/user';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useModal, EditUserTarget, ShowUsersTarget, ModalResult } from 'views/services/modal';
import { CentralKitchensActionName } from 'views/services/dropdown';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { UsersButton } from 'views/components/users-button';
import { ActionsButton } from './views/actions-button';
import { Root, Frame, List, ListHeader, ListItem, Content, Actions, NoItem } from './index.styled';

const CentralKitchens = () => {

  const auth = useAuth();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(centralKitchen?: CentralKitchen) {
    const target = centralKitchen ?? model.build();
    const tell = apply;
    modal.open('edit-central-kitchen', { target, strict: true, other: { companies: model.companies }, tell });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof CentralKitchen)) return;
    const centralKitchen = result.target as CentralKitchen;
    switch (result.state) {
      case 'created': setModel(model.add(centralKitchen)); break;
      case 'updated': setModel(model.replace(centralKitchen)); break;
      case 'deleted': setModel(model.remove(centralKitchen)); break;
    }
  }

  function selectAction(action: CentralKitchensActionName, centralKitchen: CentralKitchen) {
    switch (action) {
      case 'edit-satellite-kitchen': return editSatelliteKitchen(model.buildSatelliteKitchen(centralKitchen), centralKitchen);
      case 'edit-user': return editUser({ user: model.buildUser(centralKitchen), centralKitchen });
    }
  }

  function editSatelliteKitchen(target: SatelliteKitchen, centralKitchen: CentralKitchen) {
    const stores = model.getStoresExcept(centralKitchen.getSatelliteKitchensExcept(target));
    const tell = applySatelliteKitchen;
    modal.open('edit-satellite-kitchen', { target, strict: true, other: { stores }, tell });
  }

  function applySatelliteKitchen(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof SatelliteKitchen)) return;
    const headquarters = result.target as SatelliteKitchen;
    switch (result.state) {
      case 'created': setModel(model.applySatelliteKitchen(headquarters)); break;
      case 'updated': setModel(model.applySatelliteKitchen(headquarters)); break;
      case 'deleted': setModel(model.removeSatelliteKitchen(headquarters)); break;
    }
  }

  function editUser({ user, centralKitchen }: { user: User, centralKitchen: CentralKitchen }) {
    const target = new EditUserTarget({ user: model.buildUserEditor(user), organization: centralKitchen });
    const tell = applyUser;
    modal.open('edit-user', { target, strict: true, tell });
  }

  function applyUser(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof EditUserTarget)) return;
    const { organization } = result.target as EditUserTarget;
    switch (result.state) {
      case 'created': setModel(model.incrementUserCount(organization as CentralKitchen)); break;
    }
  }

  function showUsers(centralKitchen: CentralKitchen) {
    const target = new ShowUsersTarget({ organization: centralKitchen });
    const tell = applyUsers;
    modal.open('show-users', { target, strict: true, tell });
  }

  function applyUsers(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof ShowUsersTarget)) return;
    const { organization } = result.target;
    switch (result.state) {
      case 'updated': setModel(model.replace(organization as CentralKitchen)); break;
    }
  }

  function watchAuth() {
    if (!auth.ok) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  function watchCompanies() {
    if (!model.companies) return;
    if (!model.companies.nextToken) return;
    model.readAllCompanies().then(it => setModel(it));
  }

  function watchBrands() {
    if (!model.brands) return;
    if (model.brands.nextToken) model.readAllBrands().then(it => setModel(it));
    else setModel(model.initStores());
  }

  useEffect(watchAuth, [auth.ok]);
  useEffect(watchCollection, [model.collection]);
  useEffect(watchCompanies, [model.companies]);
  useEffect(watchBrands, [model.brands]);

  return (
    <Root>
      <Header mode={auth.user.admin ? 'admin' : ''} onCreate={() => edit()} />
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <Frame>
          {!model.list.length ? <NoInterfaceData>No Central Kitchen</NoInterfaceData> : model.list.map((centralKitchen, i) => (
            <List key={i}>
              <ListHeader>
                <Content onClick={() => edit(centralKitchen)}>
                  <div>{centralKitchen.name}</div>
                </Content>
                <Actions>
                  <div><UsersButton userCount={centralKitchen.userCount} onPush={() => showUsers(centralKitchen)} /></div>
                  <div><ActionsButton target={centralKitchen} onSelect={action => selectAction(action, centralKitchen)} /></div>
                </Actions>
              </ListHeader>
              {!centralKitchen.satelliteKitchens.documents.length && (
                <NoItem>No Satellite Kitchen</NoItem>
              )}
              {!!centralKitchen.satelliteKitchens.documents.length && centralKitchen.satelliteKitchens.documents.map((satelliteKitchen, i) => (
                <ListItem key={i}>
                  <Content onClick={() => editSatelliteKitchen(satelliteKitchen, centralKitchen)}>
                    <div>{satelliteKitchen.name}</div>
                  </Content>
                </ListItem>
              ))}
            </List>
          ))}
        </Frame>
      )}
    </Root>
  );

};

export default CentralKitchens;