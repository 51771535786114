import { Api } from 'models/services/api';
import { ReadCentralKitchensQuery, ReadCompaniesQuery, ReadBrandsQuery } from 'models/services/graphql';
import { CentralKitchenCollectionData } from 'models/entities/central-kitchen';
import { CompanyCollectionData } from 'models/entities/company';
import { BrandCollectionData } from 'models/entities/brand';

const RequestQuery = `
  query CentralKitchens($nextToken: String) {
    ${ReadCentralKitchensQuery}
    ${ReadCompaniesQuery}
    ${ReadBrandsQuery}
  }
`;

type ResponseData = {
  data: {
    readCentralKitchens: CentralKitchenCollectionData;
    readCompanies: CompanyCollectionData;
    readBrands: BrandCollectionData;
  }
}

type Result = {
  collection: CentralKitchenCollectionData;
  companies: CompanyCollectionData;
  brands: BrandCollectionData;
}

class ReadGql {

  readonly result?: Result;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(): Promise<this> {
    const api = await this.api.fetch();
    if (!api.response) throw new Error('invalid response');
    const collection = api.response.data.readCentralKitchens;
    const companies = api.response.data.readCompanies;
    const brands = api.response.data.readBrands;
    const result = { collection, companies, brands };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };